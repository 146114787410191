import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSwitch.figmaUrl.ios} codeUrl={checklists.componentSwitch.codeUrl.ios} checklists={checklists.componentSwitch.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`A simple switch toggle, which supports large (default), medium and small sizes, and an optional text label on its right side.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`To use the Legion iOS UIKit theme, you need to import one of the available themes. Currently, the following themes are supported: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <h2>{`Size`}</h2>
    <h3>{`Large`}</h3>
    <h4>{`Active: true, Disable: true, without Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-active-disabled-without-text-label.png",
      "alt": "LGNSwitch Large Active Disabled without Text Label"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: true,
    size: .lg
)
`}</code></pre>
    <h4>{`Active: true, Disable: true, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-active-disabled.png",
      "alt": "LGNSwitch Large Active Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: true,
    size: .lg,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: true, Disable: false, without Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-active-enabled-without-text-label.png",
      "alt": "LGNSwitch Large Active Enabled without Text Label"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: false,
    size: .lg
)
`}</code></pre>
    <h4>{`Active: true, Disable: false, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-active-enabled.png",
      "alt": "LGNSwitch Large Active Enabled with Text Label"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: false,
    size: .lg,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: false, Disable: true, without Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-inactive-disabled-without-text-label.png",
      "alt": "LGNSwitch Large Inactive Disabled without TextLabel"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: true,
    size: .lg
)
`}</code></pre>
    <h4>{`Active: false, Disable: true, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-inactive-disabled.png",
      "alt": "LGNSwitch Large Inactive Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: true,
    size: .lg,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: false, Disable: false, without Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-inactive-enabled-without-text-label.png",
      "alt": "LGNSwitch Large Inactive Enabled without TextLabel"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: false,
    size: .lg
)
`}</code></pre>
    <h4>{`Active: false, Disable: false, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-large-inactive-enabled.png",
      "alt": "LGNSwitch Large Inactive Enabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: false,
    size: .lg,
    textLabel: "Text Option"
)
`}</code></pre>
    <h3>{`Medium`}</h3>
    <h4>{`Active: true, Disable: true, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-medium-active-disabled.png",
      "alt": "LGNSwitch Medium Active Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: true,
    size: .md,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: true, Disable: false, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-medium-active-enabled.png",
      "alt": "LGNSwitch Medium Active Enabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: false,
    size: .md,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: false, Disable: true, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-medium-inactive-disabled.png",
      "alt": "LGNSwitch Medium Inactive Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: true,
    size: .md,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: false, Disable: false, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-medium-inactive-enabled.png",
      "alt": "LGNSwitch Medium Inactive Enabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: false,
    size: .md,
    textLabel: "Text Option"
)
`}</code></pre>
    <h3>{`Small`}</h3>
    <h4>{`Active: true, Disable: true, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-small-active-disabled.png",
      "alt": "LGNSwitch Small Active Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: true,
    size: .sm,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: true, Disable: false, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-small-active-enabled.png",
      "alt": "LGNSwitch Small Active Enabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: false,
    size: .sm,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: false, Disable: true, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-small-inactive-disabled.png",
      "alt": "LGNSwitch Small Inactive Disabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: true,
    size: .sm,
    textLabel: "Text Option"
)
`}</code></pre>
    <h4>{`Active: false, Disable: false, with Text Label`}</h4>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/ios-uikit/ios-uikit-switch-small-inactive-enabled.png",
      "alt": "LGNSwitch Small Inactive Enabled"
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: false,
    disable: false,
    size: .sm,
    textLabel: "Text Option"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial state of the switch.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines if the switch is enabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabledTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of the text when disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#212121`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabledOffTintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tint color when the switch is off and disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Tertiary300`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabledOnTintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tint color when the switch is on but disabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Success100`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabledTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of the text when enabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#212121`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabledOffTintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tint color when the switch is off and enabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Tertiary400`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabledOnTintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tint color when the switch is on and enabled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Success500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontProvider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conforms to `}<inlineCode parentName="td">{`LGNSwitchFontProvider`}</inlineCode>{`, providing fonts for the switch label based on `}<inlineCode parentName="td">{`SizeType`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onToggleChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure that gets called when the switch is toggled.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the switch, using the predefined `}<inlineCode parentName="td">{`SizeType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.lg`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sizeProvider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conforms to `}<inlineCode parentName="td">{`LGNSwitchSizeProvider`}</inlineCode>{`, defining switch sizes based on `}<inlineCode parentName="td">{`SizeType`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text displayed next to the switch.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example onToggleChange`}</h2>
    <p>{`You can use the `}<inlineCode parentName="p">{`onToggleChange`}</inlineCode>{` property to handle the switch toggle event:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let lgnSwitch = LGNSwitch(
    active: true,
    disable: false,
    size: .lg
)
lgnSwitch.onToggleChange = { [weak self] isOn in
    print("Switch is now \\(isOn ? "ON" : "OFF")")
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      